import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss'
import logo from '../../assets/img/footer_logo.svg';
import footerLinkedinLogo from '../../assets/img/linkedin_icon.svg';
import footerFacebookLogo from '../../assets/img/facebook_icon.svg';

import { useAppContext } from "../../AppContext";

function Footer (){
    const { openPromoBlock, enableScrollToAnchor } = useAppContext(); 

    const scrollingToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }


    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      // Функция для проверки видимости компонента при прокрутке
      const checkVisibility = () => {
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const middleOfPage = windowHeight * 2;
  
        // Проверяем, находится ли середина экрана выше половины страницы
        if (scrollY > middleOfPage) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      // Добавляем слушатель события прокрутки
      window.addEventListener('scroll', checkVisibility);
  
      // Вызываем функцию проверки видимости при первой загрузке
      checkVisibility();
  
      // Убираем слушатель при размонтировании компонента
      return () => {
        window.removeEventListener('scroll', checkVisibility);
      };
    }, []);

    return (
        <div className="footer">
            <div className="footer_inner">
                <div className="footer_container">
                    <div className="footer_container_logo">
                        <img src={logo} alt="simple buy"/>                       
                        <button onClick={() => {openPromoBlock(); enableScrollToAnchor()}} className="footer_demo_btn">запросить демо</button>
                    </div>
                    <div className="footer_container_contacts">
                        <h4 className="footer_container_contacts_title">Контакты</h4>
                        <div className="footer_container_contacts_info">
                            <div className="footer_container_contacts_info_item">
                                <div className="footer_info_name">
                                    <span>Наталья Силякова</span>,<br />Глава международных проектов
                                </div>
                                <div className="footer_info_email">Почта: <span>silyakova@itexp.pro</span></div>
                                <div className="footer_info_mobile">Телефон: <span>+7 926 116 78 98</span></div>
                            </div>
                            <div className="footer_container_contacts_info_item">
                                <div className="footer_info_name"><span>Андрей Котик,</span><br/>Проджект-менеджер</div>
                                <div className="footer_info_email">Почта: <span>kotik@itexp.pro</span></div>
                                <div className="footer_info_mobile">Телефон: <span>+381 63 1013396</span></div>
                            </div>
                        </div> 
                    </div>
                </div>
    
                <div className="footer_links">
                    <a href="#" target="_blank" className="footer_links_item">
                        <img src={footerLinkedinLogo} alt="link to linkedin" />
                    </a>
                    <a href="#" target="_blank" className="footer_links_item">
                        <img src={footerFacebookLogo} alt="link to facebook" />
                    </a>
                    {/* <a href="#" className="footer_links_item"></a> */}
                    <button className={`footer_scrooling_btn ${isVisible ? 'visible' : ''}`} onClick={scrollingToTop}></button>
                </div>
            </div>
        </div>
    )
}

export default Footer;
