import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import classNames from 'classnames';

import "./Header.scss";

import simplebuyLogo from "../../assets/img/simplebuy-logo.svg";
import DropDownMenu from "./DropDownMenu/DropDownMenu";
import { useAppContext } from "../../AppContext";

function Header() {

  const { openPromoBlock, enableScrollToAnchor } = useAppContext(); 

  const menuInfo = [
    {
      menuTitle: "О компании", menuItems: [{linkName: "Финтех Сервисы", link: "/fintechservices"}, {linkName: "Бизнес-концепция", link: "/business"}, {linkName: "Новости", link: "/news"}, {linkName: "Документы", link: "/documents"}]
    },
    {
      menuTitle: "Решения", menuItems: [{linkName: "Брокер", link: "/broker"}, {linkName: "Customer journey", link: "/customerjourney"}, {linkName: "Оформление заявки", link: "/application-processing"}, {linkName: "Оценка Заёмщика", link: "/borrower-assessment"}, {linkName: "Коммуникация с клиентом", link: "/communication"}, {linkName: "Управление бизнес процессами", link: "/business-process"}, {linkName: "Сопровождение займа", link: "/loan-support"}]
    },
    {
      menuTitle: "Направления", menuItems: [{linkName: "Старт-Ап проекты в финтех", link: "/start-up"}, {linkName: "Банки", link: "/banks"}, {linkName: "МФО", link: "/mfo"}, {linkName: "Ретейл", link: "/retail"}]
    }
  ];

  let [isGradient, setValue] = useState(false)
  let [isMenuOpen, setMenuState] = useState(false);
  let [isMobileMenuOpen, setMobileMenuState] = useState(false);
  
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/banks" || location.pathname === "/mfo" || location.pathname === "/retail") {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [location]);


  return (
    <header>
      <div className="container-page">
        <div className={!isGradient ? "header-content" : "header-content gradient"}>
          <div className="header-content-container">
            <Link className="header-link" to="/">
              <img src={simplebuyLogo} alt="logo" className="simplebuy-logo"/>
            </Link>
            <div className="menu-container">
              {menuInfo.map((menu, idx) => <DropDownMenu key={idx} menuInfo={menu}/>)}
              <Link className="header-link" to="/contacts">КОНТАКТЫ</Link>
              <button className="btn header-btn" onClick={() => {openPromoBlock(); enableScrollToAnchor()}}>ДЕМО</button>
              <div className={!isMenuOpen ? "burger-menu_button" : "burger-menu_button menu_button_open"} onClick={() => setMenuState(!isMenuOpen)}>
                <span className="burger-menu_line"></span>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames("drop-down-menu-list", { "gradient": isGradient }, { "drop-down-menu-list-open": isMenuOpen })}>
          {menuInfo.map((menu, idx) => (
            <div className="mobile-menu" key={idx}>

              <div className="mobile-menu-title">
                <p>{menu.menuTitle}</p>
                <span className="menu-title-arrow" onClick={() => setMobileMenuState(!isMobileMenuOpen)}></span>
              </div>
              <ul className="mobile-menu-dropdown">
                {menu.menuItems.map((item, idx) => (
                  <Link to={item.link} className="menu-link" key={idx}><span>{item.linkName}</span></Link>
                ))}
              </ul>
            </div>
          ))}
          <div className="mobile-menu" >
            <div className="mobile-menu-title">
              <Link className="menu-link" to="/contacts"><p>КОНТАКТЫ</p></Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
