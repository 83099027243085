import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import "./Preloader.scss";

function Preloader(props) {

  let [isGradient, setValue] = useState(false)

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/banks" || location.pathname === "/mfo" || location.pathname === "/retail") {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [location]);

  return (
    <div className={!isGradient ? "preloader" : "preloader gradient"}>
      <div className="loader">loading</div>
    </div>
  );
}

export default Preloader;
