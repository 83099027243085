import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import scrollToTop from "./utils/scrollToTop";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Preloader from "./components/Preloader/Preloader.jsx";
import { AppContextProvider } from "./AppContext";

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const MainScreen = React.lazy(() => delay(500).then(() => import("./pages/MainScreen/MainScreen")));
const FintechServices = React.lazy(() => delay(500).then(() => import("./pages/FintechServices/FintechServices")));
const Business = React.lazy(() => delay(500).then(() => import("./pages/Business/Business")));
const Broker = React.lazy(() => delay(500).then(() => import("./pages/Broker/Broker")));
const Documents = React.lazy(() => delay(500).then(() => import("./pages/Documents/Documents")));
const StartUp = React.lazy(() => delay(500).then(() => import("./pages/StartUp/StartUp")));
const CustomerJourney = React.lazy(() => delay(500).then(() => import("./pages/CustomerJourney/CustomerJourney")));
const Banks = React.lazy(() => delay(500).then(() => import("./pages/Banks/Banks")));
const ApplicationProcessing = React.lazy(() => delay(500).then(() => import("./pages/ApplicationProcessing/ApplicationProcessing")));
const BorrowerAssessment = React.lazy(() => delay(500).then(() => import("./pages/BorrowerAssessment/BorrowerAssessment")));
const Communication = React.lazy(() => delay(500).then(() => import("./pages/Communication/Communication")));
const BusinessProcessManagement = React.lazy(() => delay(500).then(() => import("./pages/BusinessProcessManagement/BusinessProcessManagement")));
const LoanSupport = React.lazy(() => delay(500).then(() => import("./pages/LoanSupport/LoanSupport")));
const MFO = React.lazy(() => delay(500).then(() => import("./pages/MFO/MFO")));
const Retail = React.lazy(() => delay(500).then(() => import("./pages/Retail/Retail")));
const Contacts = React.lazy(() => delay(500).then(() => import("./pages/Contacts/Contacts")));
const News = React.lazy(() => delay(500).then(() => import("./pages/News/News")));

function App() {

  const ScrollToTopOnPageChange = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      if (window.scrollY > 0) scrollToTop();
    }, [pathname]);
  };

  return (
    <BrowserRouter>
    <ScrollToTopOnPageChange />
    <AppContextProvider>
      <Header/>
        <React.Suspense fallback={<Preloader/>}>
          <Routes>
              <Route path="/" element={<MainScreen/>} />
              <Route path="/fintechservices" element={<FintechServices/>} />
              <Route path="/business" element={<Business/>} />
              <Route path="/news/*" element={<News/>} />
              <Route path="/broker" element={<Broker/>} />
              <Route path="/documents" element={<Documents/>} />
              <Route path="/start-up" element={<StartUp/>} />
              <Route path="/customerjourney" element={<CustomerJourney/>} />
              <Route path="/banks" element={<Banks/>} />
              <Route path="/application-processing" element={<ApplicationProcessing/>} />
              <Route path="/borrower-assessment" element={<BorrowerAssessment/>} />
              <Route path="/communication" element={<Communication/>} />
              <Route path="/business-process" element={<BusinessProcessManagement/>} />
              <Route path="/loan-support" element={<LoanSupport/>} />
              <Route path="/mfo" element={<MFO/>} />
              <Route path="/retail" element={<Retail/>} />
              <Route path="/contacts" element={<Contacts/>} />
          </Routes>
        </React.Suspense>
        <Footer/>
    </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
