import React from 'react';

import { Link } from "react-router-dom";

import "./DropDownMenu.scss";

function DropDownMenu(props) {

  const {menuTitle, menuItems} = props.menuInfo

  return (
    <div className="menu">
      <div className="menu-title">
        <p>{menuTitle}</p>
        <span className="menu-title-arrow top-position"></span>
      </div>
      <ul className="menu-dropdown">
        {menuItems.map((item, idx) => {
          return <Link to={item.link} className="menu-link" key={idx}><span>{item.linkName}</span></Link>
        })}
      </ul>
    </div>
  );
}

export default DropDownMenu;
