import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = ({ children }) => {
  const [isPromoBlockOpen, setPromoBlockOpen] = useState(false);
  const [scrollToAnchor, setScrollToAnchor] = useState(false);
  
  const openPromoBlock = () => {
    setTimeout(() => {
      setPromoBlockOpen(true);
    }, 500);
  };

  const closePromoBlock = () => {
    setPromoBlockOpen(false);
  };
  
  const enableScrollToAnchor = () => {
    setScrollToAnchor(true);
  };

  const disableScrollToAnchor = () => {
    setScrollToAnchor(false);
  };

  return (
    <AppContext.Provider value={{ isPromoBlockOpen, openPromoBlock, closePromoBlock, scrollToAnchor,  enableScrollToAnchor, disableScrollToAnchor }}>
      {children}
    </AppContext.Provider>
  );
};
